import Seo from "gatsby-plugin-wpgraphql-seo";
import React from "react";
import { Template } from "../../../config/wordPressConfig";
import { usePageForTemplate } from "../../queries/wpPage/usePageForTemplate";
import VTSHomeTemplate from "../04-templates/VTSHomeTemplate/VTSHomeTemplate";
import NotFoundPage from "./404";

export const IndexPage: React.FunctionComponent = () => {
  const frontPage = usePageForTemplate(Template.Home);

  if (!frontPage?.id) {
    <NotFoundPage />;
  }

  return (
    <>
      {frontPage && (
        <Seo
          post={frontPage}
          title={frontPage.seo?.title ? frontPage.seo?.title : "VITIS"}
        />
      )}

      <VTSHomeTemplate pageId={frontPage?.id as string} />
    </>
  );
};

export default IndexPage;
